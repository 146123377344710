/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.prose {
  a {
    @apply text-brand #{'!important'};
  }
  a:visited {
    @apply text-brand-900 #{'!important'};
  }
}

.cursor-close {
  cursor:
    url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIwIDIwTDQgNC4wMDAwM00yMCA0TDQuMDAwMDIgMjAiIHN0cm9rZT0iI2VlZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48L3N2Zz4=)
      12 12,
    auto;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input,
textarea {
  &:focus {
    box-shadow: none !important;
  }
}

ng-select.custom {
  .ng-select-container {
    min-height: 3rem !important;
  }
  .ng-invalid {
    border-color: #dc3545 !important;
  }
  .ng-value-container .ng-input > input {
    padding-top: 5px !important;
  }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545 !important;
}

.bounce-top {
  animation: bounce-top 0.9s linear forwards;
}

.wobble-horizontal-bottom {
  animation: wobble-horizontal-bottom 0.8s linear both;
}

@media screen and (min-width: 960px) {
  .is-mobile.is-mobile,
  .is-mobile > *,
  .is-mobile > .flex {
    display: none !important;
  }
}

@media screen and (max-width: 959px) {
  .header-element {
    display: flex !important;
    flex-direction: row !important;
    padding: 0.5rem !important;
    height: 60px;
  }

  .is-desktop.is-desktop,
  .is-desktop > *,
  .is-desktop > .flex {
    display: none !important;
  }
}

@keyframes hotToastEnterAnimationNegative {
  0% {
    opacity: 0.5;
    transform: translate3d(0, -80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastEnterAnimationPositive {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastExitAnimationPositive {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 130px, -1px) scale(0.5);
  }
}

@keyframes hotToastExitAnimationNegative {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -130px, -1px) scale(0.5);
  }
}

@keyframes ping {
  75%,
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes bounce-top {
  0% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-2px);
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-1px);
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes wobble-horizontal-bottom {
  0%,
  100% {
    transform: translateX(0);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-15px) rotate(-12deg);
  }
  30% {
    transform: translateX(8px) rotate(12deg);
  }
  45% {
    transform: translateX(-8px) rotate(-7.2deg);
  }
  60% {
    transform: translateX(5px) rotate(4.8deg);
  }
  75% {
    transform: translateX(-3px) rotate(-2.4deg);
  }
}

@keyframes zoom-prod {
  0% {
    position: relative;
    width: 10%;
  }
  100% {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    border-radius: 0;
    width: 100%;
    height: auto;
  }
}
